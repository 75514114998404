import classnames from 'classnames';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import Slider from 'react-slick';

import CoverPoster from '../videos/cover.jpg';
import CoverVideo from '../videos/cover.mp4';
import CoverVideoOgv from '../videos/cover.ogv';
import CoverVideoWebm from '../videos/cover.webm';

import PoolPoster from '../videos/pool.jpg';
import PoolVideo from '../videos/pool.mp4';
import PoolVideoOgv from '../videos/pool.ogv';
import PoolVideoWebm from '../videos/pool.webm';

import ArrowLeft from '../assets/arrow-left.svg';
import ArrowRight from '../assets/arrow-right.svg';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const ARROW_HEIGHT = 60;

const NextArrow = ({ onClick }) => (
  <ArrowRight
    className="carousel-arrow carousel-arrow--next"
    height={ARROW_HEIGHT}
    onClick={onClick}
  />
);

const PrevArrow = ({ onClick }) => (
  <ArrowLeft
    className="carousel-arrow carousel-arrow--prev"
    height={ARROW_HEIGHT}
    onClick={onClick}
  />
);

const Carousel = ({ locale = 'en' }) => {
  const isMobile = typeof window !== 'undefined' && window.innerWidth <= 768;

  const [active, setActive] = useState(0);

  const data = useStaticQuery(graphql`
    {
      images: allFile(filter: { sourceInstanceName: { eq: "carousel" } }) {
        edges {
          node {
            name
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      carousel: allCarouselJson(sort: { fields: [order], order: ASC }) {
        edges {
          node {
            id
            categoryId
            ptBR {
              title
              description
            }
            en {
              title
              description
            }
          }
        }
      }
      categories: allCategoriesJson(sort: { fields: [order], order: ASC }) {
        edges {
          node {
            id
            ptBR {
              name
              slug
            }
            en {
              name
              slug
            }
          }
        }
      }
    }
  `);

  const settings = {
    beforeChange(oldIndex, newIndex) {
      setActive(newIndex);
    },
    dots: false,
    infinite: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  const gLocale = locale.replace('-', '');
  const baseUrl = locale === 'pt-BR' ? '/pt-BR/trabalhos/' : '/work/';
  const bordadoCategory = data.categories.edges.find(
    ({ node }) => node.id === '1'
  ).node;
  const fotosCategory = data.categories.edges.find(
    ({ node }) => node.id === '6'
  ).node;

  return (
    <div className="carousel">
      <Slider {...settings}>
        <a
          className={classnames('slide', {
            active: active === 0
          })}
          key="video"
          href={`${baseUrl}${fotosCategory[gLocale].slug}/`}
          title="Photos and videos"
        >
          <video
            autoPlay
            className="background-video"
            controls={isMobile}
            loop
            muted
            playsInline
            poster={PoolPoster}
          >
            <source src={PoolVideo} type="video/mp4" />
            <source src={PoolVideoWebm} type="video/webm" />
            <source src={PoolVideoOgv} type="video/ogv" />
          </video>
        </a>
        <a
          className={classnames('slide', {
            active: active === 0
          })}
          key="video"
          href={`${baseUrl}${bordadoCategory[gLocale].slug}/`}
          title="Textile Art"
        >
          <video
            autoPlay
            className="background-video"
            controls={isMobile}
            loop
            muted
            playsInline
            poster={CoverPoster}
          >
            <source src={CoverVideo} type="video/mp4" />
            <source src={CoverVideoWebm} type="video/webm" />
            <source src={CoverVideoOgv} type="video/ogv" />
          </video>
        </a>
        {data.carousel.edges.map(({ node }, index) => {
          const imageIndex = data.images.edges.findIndex(
            image => image.node.name === `carousel-${node.id}`
          );

          const category = data.categories.edges.find(
            ({ node: category }) => parseInt(category.id) === node.categoryId
          ).node;

          return (
            <a
              className={classnames('slide', {
                active: active === index + 1
              })}
              key={node.id}
              href={`${baseUrl}${category[gLocale].slug}/`}
              title={category[gLocale].name}
            >
              <Img
                fluid={data.images.edges[imageIndex].node.childImageSharp.fluid}
              />
              {/* <div className="tagline">
                <h2>{node[locale.replace('-', '')].title}</h2>
                <h4>{node[locale.replace('-', '')].description}</h4>
              </div> */}
            </a>
          );
        })}
      </Slider>
    </div>
  );
};

Carousel.propTypes = {
  locale: PropTypes.string
};

export default Carousel;
